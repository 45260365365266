import request from "@/Rest";
// const containsKey = (obj, key) => Object.keys(obj).includes(key);
import { LOCATION_URLS } from "@/Rest/api/urls";

export async function locationData({ commit }) {
  let to_Response = { success: false };

  await request
    .GET(LOCATION_URLS.baseUrl)
    .then((response) => {
      if (response.status === 200) {
        let data = response.data;
        commit("setGuardianId", data.Guardian_id);
        commit("setGuardianRole", data.Guardian_role);
        commit("setLocations", data.Locations);
        to_Response["success"] = true;
        to_Response["data"] = data;
      }
    })
    .catch((err) => {
      commit("registerError", err);
    });
  return to_Response;
}

export async function createLocation({ commit }, name) {
  let to_Response = { success: false };

  await request
    .POST(LOCATION_URLS.baseUrl, {
      name: name,
    })
    .then((response) => {
      if (response.status === 201) {
        let data = response.data;
        to_Response["data"] = data;
        to_Response["success"] = true;
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        let data = err.response.data;
        to_Response["data"] = data;
      }
      commit("registerError", err);
    });
  return to_Response;
}

export async function editLocation({ commit }, { locationId, info }) {
  let to_Response = { success: false };
  let payload = {
    name: info.name,
  };

  await request
    .PATCH(LOCATION_URLS.editLocation(locationId), payload)
    .then((response) => {
      let data = response.data;
      to_Response["data"] = data;
      to_Response["success"] = true;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        let data = err.response.data;
        to_Response["data"] = data;
      }
      commit("registerError", err);
    });
  return to_Response;
}
