import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

export const initialState = () => ({
  accessToken: "",
  refreshToken: "",
  userData: {},
  isLogged: false,
  timeZone: {},
  gender: "",
  notifications: [],
  error: false,
  errorMessage: "",
  mfa: {
    totp: {
      link: "",
      recovery: [],
    },
  },
});

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
