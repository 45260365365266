import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

export const initialState = () => ({
  taskId: "",
  taskName: "",
  taskAdmin: {},
  taskDelegates: [],
  taskUsers: [],
  guardianId: "",
  guardianRole: "",
  tasks: [],
  gropus: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
