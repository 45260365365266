import request from "@/Rest";
import { TASK_URLS } from "@/Rest/api/urls";

export async function tasksData({ commit }) {
  let to_Response = { success: false };

  await request
    .GET(TASK_URLS.baseUrl)
    .then((response) => {
      if (response.status === 200) {
        let data = response.data;
        // commit("setGuardianId", data.Guardian_id);
        // commit("setGuardianRole", data.Guardian_role);
        commit("setTasks", data.Tasks);
        to_Response["success"] = true;
        to_Response["data"] = data;
      }
    })
    .catch((err) => {
      commit("registerError", err);
    });

  return to_Response;
}

export async function createTask({ commit }, name, description) {
  let to_Response = { success: false };

  await request
    .POST(TASK_URLS.baseUrl, {
      name: name,
      description: description,
    })
    .then((response) => {
      if (response.status === 201) {
        let data = response.data;
        to_Response["data"] = data;
        to_Response["success"] = true;
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        let data = err.response.data;
        to_Response["data"] = data;
      }
      commit("registerError", err);
    });
  return to_Response;
}

export async function editTask({ commit }, { taskId, info }) {
  let to_Response = { success: false };
  let payload = {
    name: info.name,
    description: info.description,
  };

  await request
    .PATCH(TASK_URLS.editTask(taskId), payload)
    .then((response) => {
      let data = response.data;
      to_Response["data"] = data;
      to_Response["success"] = true;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        let data = err.response.data;
        to_Response["data"] = data;
      }
      commit("registerError", err);
    });
  return to_Response;
}

export async function groupsData({ commit }) {
  let to_Response = { success: false };

  await request
    .GET(TASK_URLS.groupBaseUrl)
    .then((response) => {
      if (response.status === 200) {
        let data = response.data;
        commit("setGroups", data);
        to_Response["success"] = true;
        to_Response["data"] = data;
      }
    })
    .catch((err) => {
      commit("registerError", err);
    });

  return to_Response;
}

export async function createGroup({ commit }, name) {
  let to_Response = { success: false };
  let payload = {
    name: name,
  };

  await request
    .POST(TASK_URLS.groupBaseUrl, payload)
    .then((response) => {
      if (response.status === 201) {
        to_Response["data"] = response.data;
        to_Response["success"] = true;
      }
    })
    .catch((err) => {
      commit("registerError", err);
    });
  return to_Response;
}

export async function createTaskGroup({ commit }, task, group) {
  let to_Response = { success: false };
  let payload = {
    task: task,
    group: group,
  };

  await request
    .POST(TASK_URLS.taskGroupBaseUrl, payload)
    .then((response) => {
      if (response.status === 201) {
        to_Response["data"] = response.data;
        to_Response["success"] = true;
      }
    })
    .catch((err) => {
      commit("registerError", err);
    });
  return to_Response;
}
