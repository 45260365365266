import { axios } from "@/plugins/vue-axios";

export const GET = async (path, config) => {
  return axios.get(path, config);
};

export const POST = async (path, payload, config) => {
  return await axios.post(path, payload, config);
};

export const PUT = async (path, payload, config) => {
  return await axios.put(path, payload, config);
};

export const PATCH = async (path, payload, config) => {
  return await axios.patch(path, payload, config);
};

export const PATH = async (path, payload, config) => {
  return await axios.put(path, payload, config);
};

export const DELETE = async (path, payload, config) => {
  return await axios.delete(path, { data: payload }, config);
};
