import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export function getLocationId(state) {
  state.locationId = cookies.get("location_id");
  return state.locationId;
}

export function getLocationName(state) {
  state.locationName = cookies.get("location-name");
  return state.locationName;
}

export function getLocations(state) {
  return state.locations;
}
