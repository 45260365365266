import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

export const initialState = () => ({
  locationId: "",
  locationName: "",
  locationAdmin: {},
  locationDelegates: [],
  locationUsers: [],
  guardianId: "",
  guardianRole: "",
  locations: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
