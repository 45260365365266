import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export function setGuardianId(state, id) {
  cookies.set("guardian_id", id);
  state.guardianId = id;
}

export function setGuardianRole(state, role) {
  cookies.set("guardian_role", role);
  state.guardianId = role;
}

export function setTasks(state, tasks) {
  state.tasks = tasks;
}

export function setGroups(state, groups) {
  state.groups = groups;
}

export function registerError(state, payload) {
  state.error = true;
  state.errorMessage = payload;
}

export function cleanErrors(state) {
  state.error = false;
  state.errorMessage = "";
}
