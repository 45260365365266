import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export function getTaskId(state) {
  state.taskId = cookies.get("task_id");
  return state.taskId;
}

export function getTaskName(state) {
  state.taskName = cookies.get("task-name");
  return state.taskName;
}

export function getTasks(state) {
  return state.tasks;
}

export function getGroups(state) {
  return state.groups;
}
